  <div class="tangy-content tangy-full-width">
      <div>
        <div #container></div>
        {{'Group'|translate}}: {{groupDetails?.label}}<br>
        {{'Group ID'}}: {{groupDetails._id}}<br>
        {{'Form'|translate}}: {{meta?.form.title}}
      </div>
      <br><br><br>
    <div>
        <div style="page-break-after: always;" *ngFor="let section of meta?.items">
          <div style="font-size: 32px;" *ngFor="let input of section.inputs; let index=index">
            {{input.label}}
            <p *ngIf="input.gridItem">{{input.name}}</p>
            <span *ngIf="input.gridItem"><div *ngFor="let parts of input.gridParts">
              <span *ngFor="let part of parts">{{part.label +'  '}}</span>
              <hr/>
            </div>
            <br/>
          </span>
        </div>
        </div>
      </div>
  </div>