<app-breadcrumb [title]="title" [breadcrumbs]="breadcrumbs"></app-breadcrumb>
<div class="device-sheet-btn">
  <a routerLink="../device-sheet">
    <button mat-raised-button color="accent">
      Export Device Sheet
    </button>
  </a>
</div>
<div class="device-list-btn">
  <button mat-raised-button color="warn" (click)="export()" [disabled]="isExporting">{{'Export Device List'|translate}}</button>
</div>
<div class="table-container">
  <div class="table-legend">
    <p>Scroll to the right to see additional fields. Some of the information in this table are reported from the most recent tablet sync. Detailed information about some of these fields:</p>
    <ul>
      <li>All Docs on Tablet: All records on the tablet.</li>
      <li>Form Responses on Tablet for Location: This is a subset of "All Docs on Tablet." All tablets in the same location should have the same number of form responses if they are up-to-date on sync.</li>
      <li>Network Statistics: This value is an estimated value reported from the client app's browser instance; it is not the result of a network query at the time of sync. Consider it as a general indicator of network quality. </li>
    </ul>
  </div>

    <table mat-table [dataSource]="deviceInfos" class="mat-elevation-z8">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let element"> {{element._id.substr(0,6)}} </td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th class="w-170" mat-header-cell *matHeaderCellDef> Description </th>
        <td mat-cell *matCellDef="let element"><div class="location-cell" [innerHTML]="element.description"></div></td>
      </ng-container>
      <ng-container matColumnDef="assigned-location">
        <th class="w-170" mat-header-cell *matHeaderCellDef> Assigned Location </th>
        <td mat-cell *matCellDef="let element"><div class="location-cell" [innerHTML]="element.assignedLocation"></div></td>
      </ng-container>
      <ng-container matColumnDef="sync-location">
        <th class="w-170" mat-header-cell *matHeaderCellDef> Sync Settings </th>
        <td mat-cell *matCellDef="let element"><div class="location-cell" [innerHTML]="element.syncLocations"></div></td>
      </ng-container>
      <ng-container matColumnDef="claimed">
        <th mat-header-cell *matHeaderCellDef> Claimed </th>
        <td mat-cell *matCellDef="let element">
          <mwc-icon *ngIf="element.claimed">done</mwc-icon>
        </td>
      </ng-container>
      <ng-container matColumnDef="registeredOn">
        <th class="w-100" mat-header-cell *matHeaderCellDef> Registered on </th>
        <td mat-cell *matCellDef="let element"> {{element.registeredOn}} </td>
      </ng-container>
      <ng-container matColumnDef="syncedOn">
        <th class="w-100" mat-header-cell *matHeaderCellDef> Synced on </th>
        <td mat-cell *matCellDef="let element"> {{element.syncedOn}} </td>
      </ng-container>
      <ng-container matColumnDef="updatedOn">
        <th class="w-100" mat-header-cell *matHeaderCellDef> Updated on </th>
        <td mat-cell *matCellDef="let element"> {{element.updatedOn}} </td>
      </ng-container>
      <ng-container matColumnDef="version">
        <th mat-header-cell *matHeaderCellDef> Build ID </th>
        <td mat-cell *matCellDef="let element"> {{element.version?.substring(0,8)}} </td>
      </ng-container>
      <ng-container matColumnDef="tagVersion">
        <th class="w-100" mat-header-cell *matHeaderCellDef> Tag Version </th>
        <td mat-cell *matCellDef="let element">
          {{element.versionTag}}
        </td>
      </ng-container>
      <ng-container matColumnDef="tangerineVersion">
        <th mat-header-cell *matHeaderCellDef> Tangerine Version </th>
        <td mat-cell *matCellDef="let element">
          {{element.tangerineVersion}}
        </td>
      </ng-container>
      <ng-container matColumnDef="dbDocCount">
        <th mat-header-cell *matHeaderCellDef> All Docs on Tablet </th>
        <td mat-cell *matCellDef="let element">
          {{element.dbDocCount}}
        </td>
      </ng-container>
      <ng-container matColumnDef="localDocsForLocation">
        <th mat-header-cell *matHeaderCellDef> Form Responses on Tablet for Location </th>
        <td mat-cell *matCellDef="let element">
          {{element.localDocsForLocation}}
        </td>
      </ng-container>
      <ng-container matColumnDef="network">
        <th mat-header-cell *matHeaderCellDef> Network Statistics </th>
        <td mat-cell *matCellDef="let element">
          <ng-template [ngIf]="element.effectiveConnectionType">
            <div>Network Type: {{element.effectiveConnectionType}}</div>
          </ng-template>
          <ng-template [ngIf]="element.duration">
            <div>Duration: {{element.duration}}</div>
          </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="errorMessage">
        <th mat-header-cell *matHeaderCellDef> Errors </th>
        <td mat-cell *matCellDef="let element">
          <ng-template [ngIf]="element.replicationStatus?.info?.errors?.length > 0"><div>{{element.replicationStatus?.info.errors | json}}</div></ng-template>
          <ng-template [ngIf]="element.replicationStatus?.pullError"><div>Pull error: {{element.replicationStatus?.pullError}}</div></ng-template>
          <ng-template [ngIf]="element.replicationStatus?.pushError"><div>Push error: {{element.replicationStatus?.pushError}}</div></ng-template>
        </td>
      </ng-container>
      
      <!-- Star Column -->
      <ng-container matColumnDef="star" stickyEnd>
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element">
          <div>
            <button mat-stroked-button color="primary" [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item *ngIf="!element.claimed" (click)="getDeviceRegistrationCode(element._id)">
                <mat-icon>fingerprint</mat-icon><span>{{'registration code'|translate}}</span>
              </button>
              <button mat-menu-item (click)="editDevice(element._id)">
                <mat-icon>edit</mat-icon><span>{{'edit'|translate}}</span>
              </button>
              <button mat-menu-item (click)="viewSyncLog(element._id)">
                <mat-icon>view_list</mat-icon><span>{{'view sync log'|translate}}</span>
              </button>
              <button mat-menu-item (click)="resetDevice(element._id)">
                <mat-icon>settings_backup_restore</mat-icon><span>{{'reset'|translate}}</span>
              </button>
              <button mat-menu-item (click)="deleteDevice(element._id)">
                <mat-icon>delete</mat-icon><span>{{'delete'|translate}}</span>
              </button>
            </mat-menu>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="devicesDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: devicesDisplayedColumns;"></tr>
    </table>
</div>
    <paper-fab mat-raised-button icon="add" color="accent" class="action" (click)="generateDevices()">
    </paper-fab>
